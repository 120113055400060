'use strict';

// Copied from BS, however content was the same across sites.
// Potentially should be refactored into more robust way.

var processInclude = require('theme/js/util');

$(document).ready(function () {
    processInclude(require('theme/js/components/widgets/productTile'));
    // processInclude(require('theme/js/product/quickView'));
});
