'use strict';

module.exports = function () {
    var productTileWrapper = $('.widget-product-grid-inner-wrapper .experience-hsngassets-producttile .product');

    productTileWrapper.each(function (index) {
        var productTile = $(this).children();
        $(this).attr('data-tracking-position', index + 1);

        if (productTile.length === 0) {
            $(this).parent().remove();
        }
    });
};
